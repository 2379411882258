import $ from 'jquery';
import TweenLite from "gsap/gsap-core";
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import './vendor/font-awesome/css/font-awesome.min.css';

import './vendor/font-awesome/css/all.min.css';
import './vendor/themify-icons/css/themify-icons.css';
import './vendor/animate/animate.min.css';
import './vendor/glightbox/css/glightbox.css';
// import './vendor/tiny-slider/tiny-slider.css';
import './vendor/aos/aos.css';

import './css/style.css';

import "./vendor/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./vendor/aos/aos.js";
import "./vendor/glightbox/js/glightbox.js";
import Sticky from './vendor/sticky-js/sticky.min.js';

import "./scss/style.scss"
import "./js/jquery.event.move.js"
import "./js/jquery.twentytwenty.js"
import "./css/twentytwenty.css"

stickyHeader();
stickyContent();
faq();

$('#contact-form').submit(function (e) {
    e.preventDefault();
    if (!document.getElementById("contact-form").checkValidity()) {
        document.getElementById("contact-form").reportValidity();
    } else {
        send();
    }
});

$(function(){
    $(".before-after-container").twentytwenty();
});

function faq(){
    let accordionItems = document.querySelectorAll('.accordion-item');

    accordionItems.forEach(item => {
        let accordionButton = item.querySelector('.accordion-button');
        let accordionCollapse = item.querySelector('.accordion-collapse');

        accordionButton.addEventListener('click', () => {
            let isCollapsed = accordionButton.classList.contains('collapsed');

            accordionItems.forEach(otherItem => {
                let otherButton = otherItem.querySelector('.accordion-button');
                let otherCollapse = otherItem.querySelector('.accordion-collapse');

                otherButton.classList.add('collapsed');
                otherCollapse.classList.remove('show');
            });

            if (isCollapsed) {
                accordionButton.classList.remove('collapsed');
                accordionCollapse.classList.add('show');
            } else {
                accordionButton.classList.add('collapsed');
                accordionCollapse.classList.remove('show');
            }
        });
    });
}

function send() {
    var phone = $('#contact-form').find('[name="phone"]');
    var name = $('#contact-form').find('[name="name"]');
    var email = $('#contact-form').find('[name="email"]');
    var comment = $('#contact-form').find('[name="comment"]');

    var data = {
        phone: phone.val(),
        name: name.val(),
        email: email.val(),
        comment: comment.val()
    };

    $.ajax({
        type: 'POST',
        url: '/post-feedback',
        data: data,
        success: function (response) {
            $('#submit-button').hide();
            if (response.status === 'success') {
                phone.val('');
                name.val('');
                email.val('');
                comment.val('');
                $('#success-alert').show();
            } else {
                $('#error-alert').show();
            }
        },
        error: function (response) {
            $('#error-alert').show();
        }
    });
}

function stickyHeader() {
    $(function () {
        var stickyHeight = $('.navbar-sticky').offsetHeight;
        document.addEventListener('scroll', function (event) {
            var scTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scTop >= 400) {
                $('#sticky-space').addClass('active');
                $("#sticky-space.active").height(stickyHeight);
                $('.navbar-sticky').addClass('navbar-sticky-on');
            } else {
                $('#sticky-space').removeClass('active');
                $('#sticky-space').height('0');
                $('.navbar-sticky').removeClass("navbar-sticky-on");
            }
        });
    });
}

function stickyContent() {
    if ($('[data-sticky]')) {
        var sticky = new Sticky('[data-sticky]');
    }
}

$('#book-now-button').on('click', function (e) {
    e.preventDefault();
    if (!document.getElementById("book-now-form").checkValidity()) {
        document.getElementById("book-now-form").reportValidity();
        return;
    }

    var phone = $('#form-phone');
    var name = $('#form-name');
    var email = $('#form-email');
    var comment = $('#form-comment');

    var data = {
        phone: phone.val(),
        name: name.val(),
        email: email.val(),
        comment: comment.val()
    };

    $.ajax({
        type: 'POST',
        url: '/post-feedback',
        data: data,
        success: function (response) {
            if (response.status === 'success') {
                phone.val('');
                name.val('');
                email.val('');
                comment.val('');
                $('#success-alert').show();
                setTimeout(function () {
                    $('#book-now').modal('hide');
                }, 2500);
            } else {
                $('#error-alert').show();
            }
        },
        error: function (response) {
            $('#error-alert').show();
        }
    });
});

$('#subscription-button').on('click', function (e) {
    e.preventDefault();
    if (!document.getElementById("subscription-form").checkValidity()) {
        document.getElementById("subscription-form").reportValidity();
        return;
    }
    var email = $('#subscription-form-email');
    var data = {
        phone: '',
        name: '',
        email: email.val(),
        comment: 'Subscription from bottom form.'
    };

    $.ajax({
        type: 'POST',
        url: '/post-feedback',
        data: data,
        success: function (response) {
            if (response.status === 'success') {
                email.val('');
                $('#subscribe-success-alert').show();
                setTimeout(function () {
                    $('#subscribe-success-alert').hide();
                }, 2500);
            } else {
                $('#subscribe-error-alert').show();
                setTimeout(function () {
                    $('#subscribe-error-alert').hide();
                }, 2500);
            }
        },
        error: function (response) {
            $('#subscribe-error-alert').show();
            setTimeout(function () {
                $('#subscribe-error-alert').hide();
            }, 2500);
        }
    });
});

$('#subscription-blog-button').on('click', function (e) {
    e.preventDefault();
    if (!document.getElementById("subscription-blog-form").checkValidity()) {
        document.getElementById("subscription-blog-form").reportValidity();
        return;
    }
    var email = $('#subscription-blog-form-email');
    var data = {
        phone: '',
        name: '',
        email: email.val(),
        comment: 'Subscription from blog form.'
    };

    $.ajax({
        type: 'POST',
        url: '/post-feedback',
        data: data,
        success: function (response) {
            if (response.status === 'success') {
                email.val('');
                $('#subscribe-blog-success-alert').show();
                setTimeout(function () {
                    $('#subscribe-blog-success-alert').hide();
                }, 2500);
            } else {
                $('#subscribe-blog-error-alert').show();
                setTimeout(function () {
                    $('#subscribe-blog-error-alert').hide();
                }, 2500);
            }
        },
        error: function (response) {
            $('#subscribe-blog-error-alert').show();
            setTimeout(function () {
                $('#subscribe-blog-error-alert').hide();
            }, 2500);
        }
    });
});